<template>
  <div></div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "AzSsoAuth",
  methods: {
    ...mapActions(["getSession"]),
  },
  async mounted() {
    let search = location.search.substring(1);
    let obj = JSON.parse(
      '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
      function (key, value) {
        return key === "" ? value : decodeURIComponent(value);
      }
    );
    if (obj && obj.access_token) {
      this.$axios.defaults.headers.common.Authorization = null;
      const fd = new FormData();
      fd.append("token", obj.access_token);
      fd.append("refresh", obj.refresh);
      this.$axios({
        method: "POST",
        url: "/api/auth/sso-login",
        data: fd,
      })
        .then(async (response) => {
          const result = response.data;
          localStorage.setItem("token", result.access_token);
          localStorage.setItem("refresh", result.refresh);
          this.$axios.defaults.headers.common.Authorization =
            "Bearer " + result.access_token;
          if (await this.getSession()) {
            if (obj.back_url) {
              let url;
              let route = this.$router.resolve(obj.back_url).resolved;
              if (route.name !== "404") {
                this.$router.push(route);
              } else {
                try {
                  url = new URL(obj.back_url);
                } catch {
                  url = new URL("https://" + obj.back_url);
                }
                window.open(url, "_self");
              }
            } else {
              this.$router.push({
                name: "MainPage",
              });
            }
          } else {
            this.$router.push({ name: "MainPage" });
          }
          return true;
        })
        .catch((error) => {
          return false;
        });
    } else {
       this.$router.push({ name: "MainPage" });
    }
  },
};
</script>

<style>
</style>